// About.js
import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";

class DanshaGradings extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Dansha gradings | Syllabus | 16 Man Kumite</h1>
                <p>It is expected that by shodan level students have approximately 90% knowledge of dojo terminology in
                    the Japanese language.</p>
                <p>Gradings are conducted mostly in Japanese as follows:</p>

                <h2>Syllabus</h2>
                <p>Traditional warm up</p>
                <p>Basic through to advanced techniques</p>
                <p>Moving techniques</p>
                <p>Free choice - techniques announced in Japanese performed as functional combinations</p>
                <p>Pad work</p>

                <p>Kakie + finishing techniques</p>

                <p>Body conditioning</p>

                <p>Chishi - all routines</p>

                <p>Ne Waza - ground techniques</p>

                <p>Ippon kumite</p>

                <p>Kata - including all previous kata, plus Sanchin shime testing</p>

                <p>Kata bunkai + renzoku bunkai</p>

                <p>(Supplimentary kata, bunkai and weaponry at Examiner's discretion)</p>

                <p>16 man kumite - 16 x 1.5 minute rounds, no stopping</p>

                <p>Belt whipping - 20 seconds, by 2 senpai</p>


                <h2>Origins of the 16 man kumite</h2>
                <p>The part that keeps every promising Black Belt up the night before the test, at least dwelling on it
                    for a while, the sixteen man kumite. After about two and half hours of Dansha testing students are
                    required to fight 16 individuals for one and half minute rounds. This totals 24 minutes of
                    continuous sparring (semi contact).</p>

                <p>Not only is a standard of fitness important for this but also a strong mental condition (spirit).
                    Irrespective how fit a participant is, he or she, if they put 100% effort into it will be worn out
                    at the end of 24 minutes. I have seen people take this test and try to conserve energy for the last
                    few fights, this is not what I am looking for. A good 16 man fight (when I say man, I of course mean
                    mankind, both man and woman) is when students give their all physically and the spirit then carries
                    them onto the end. When we are tired and feeling sick in the stomach, a little bruised, it is all to
                    easy to give up, in fact the body sends messages to the brain for this to happen. Unless the spirit
                    can override this request we will feel like giving up throughout the sparring and it will be
                    noticeable to the examiner.</p>

                <p>After accomplishment of the 16 man fight my black belts are allowed to wear a Black gi whenever they
                    want to during training unless otherwise stated to wear the white one. The black gi to us in the
                    TOGKA is a symbol of the 16 man fight.</p>

                <p>I know by now some of you out there are saying 16 man fight that's nothing, Kyokushinkai style does
                    100 man kumite. I had the of honour of meeting one of the first few "Gaijin" (None Japanese) to have
                    taken such a test, Sensei John Jarvis of New Zealand who in his wisdom reduced this ritual from 100
                    man to a 40 man fight for his Shodan gradings. I was in New Zealand on the invitation of Sensei
                    Jarvis teaching at one of his Gasshukus (training camps) when a Shodan test was taken. I always
                    remember counting to myself how many fights passed before the student in my estimation was at the
                    mercy of his attackers, it was 16. For the next 24 fights the poor man did not know if he was coming
                    or going. He eventually lost a couple of teeth to an over enthusiastic black belt (Nidan) as he
                    inadvertently walked into a "pulled" Jodan Kizame-tsuki, and that was "only" a 40 man fight. I vowed
                    to myself that day none of my students would lose their teeth or any other body parts in such a
                    way.</p>

                <p>I did like the idea of some kind of endurance test for the spirit and hardiness of body, so I
                    included the 16 man fight and like all good teachers should teach by example, I under went the 16
                    man fight too. I will not give my students tasks to do that I can not do myself, being satisfied
                    that the 16 man fight can bring even the mighty to their knees. I have kept it at that number ever
                    since.</p>

                <p>[N.B. Karateka over the age of 50 are not required to participate in the 16 man kumite. Strikes to
                    the face should be open handed.]</p>


                <h2>Purpose of the belt whipping</h2>
                <p>I am sure many of you are wondering why in the TOGKA Shodan grading syllabus are requirements for the
                    student to undertake a 16 man fight then a 20 second whipping, carried out by 2 Senpai using their
                    black belt as whips (approx. 30 lashes are administered).</p>

                <p>Some of you are wondering how a mild beating up to a bloody nose or fat lip, followed by a flogging
                    can help a new black belt on his Dansha path. I hope after reading this article my logic is made
                    clear to you.</p>

                <p>A student of TOGKA is ready for black belt after about 4 to 5 years of training twice a week
                    regularly. In this time their character grows stronger and they become more assertive in many ways.
                    It comes with traditional training methods. But although we seek only goodness from training the
                    badness can follow, by this I mean we gain confidence from Karate training but over confidence will
                    then follow leading to Ego inflation. I have in my 42 years of practicing Karate-do seen this happen
                    to all martial artists in varying degrees. So it must be a natural process in a world of DUALITY to
                    experience BOTH degrees of whatever we seek (in this example confidence). The trick is then to
                    realize there is ‘’a third position’’ esoterically named the triune. It is where harmony is said to
                    exist. It is in basic terms the middle path as shown on the diagram.</p>

                <img src="/images/theMiddlePath.jpg" alt="The Middle Path"/>

                <p>I really think this is the quest of the Dansha journey to find the middle path. The Kyu journey
                    starts with no confidence or very little and we build as much as we can on the way, using rigorous
                    training as a tool to strengthen both body & mind. But to find the middle path we need the help of
                    the soul. This is where the whipping comes in. It can promote in some people the awakening of the
                    soul. This happening in spiritual terms is called "Opening the third eye" or the eye of
                    enlightenment. It is well known in Bushido that with the help of rigorous training over many years,
                    a student might awaken the soul and become more as one with their arts and surroundings. Rigorous
                    training puts the body in pain and when the body is stressed the mentalism gets stronger. In my
                    opinion the 16man fight and whipping can do that. Many wise men of the past such as Guru-Magi and
                    Avatars have put their bodies through pain to attain enlightenment. This is a great example of how
                    the hard and soft work together on a higher level. I believe in the science of this discipline and
                    try to give my new black belts this awakening as a tool for their Dansha journey.</p>

                <p>[FOOT NOTE]</p>

                <p>Some people put their bodies through a rigorous discipline lasting a life time to gain enlightenment.
                    Although the 16 man fight and whipping can stir "the third eye" it is for the individual to then
                    keep it awake.</p>


                <p>- Sensei Graham Ravey.</p>


                <p>[N.B. Protective eye wear must be worn to prevent accidental injury.]</p>
            </Layout>);
    }
}

export default DanshaGradings;
